.upload-btns {
  height: 40px;
}
.upload-btns .el-upload {
  height: 40px !important;
  border: none !important;
}
.Score-ruleForm .el-form-item {
  display: flex;
  align-items: center;
}
.sjd {
  width: 5.2rem;
}
.sjd input {
  border: none;
  border-bottom: 1px solid #dcdfe6;
  border-radius: 0;
}
